<template>
    <div>
        <CRow v-show="mostrarEmpresas">
            <CCol md="3">
                <multiselect 
                    v-show="mostrarEmpresas"
                    class="m-0 d-inline-block mb-2" 
                    v-model="empresaSelected"
                    deselect-label="" 
                    label="nombreEmpresa" 
                    placeholder="Mi Empresa" 
                    select-label=""
                    selectedLabel=""
                    :options="empresasTrabajador" 
                    :searchable="true"
                    :loading="isLoadingEmpresas"
                    @input="seleccionarEmpresa">
                </multiselect>
            </CCol>
        </CRow>
        <CRow>
            <CCol md="6">
                <CCard accent-color="secondary" class="mt-1 mb-1">
                    <CCardHeader>
                        <div class="card-header-actions">
                            <CLink  class="card-header-action btn-minimize float-right" @click="isCollapsedInfo = !isCollapsedInfo">
                                <CIcon :name="`cil-chevron-${isCollapsedInfo ? 'bottom' : 'top'}`"/>
                            </CLink>
                            <router-link to="perfil">Ver mi perfil</router-link>
                        </div>

                        <CRow>
                            <CCol><strong>Mi Información</strong></CCol>
                        </CRow>
                    </CCardHeader>
                    <CCollapse :show="isCollapsedInfo" :duration="400">
                        <CCardBody>
                            <InfoTrabajadorComponente 
                            :infoTrabajador="infoTrabajador"
                            :isLoadingInfoPersonal="isLoadingInfo"
                            :isLoadingInfoLaboral="isLoadingInfo"
                            :verRut="true"
                            :verNombre="true"
                            :verTelefono="false"
                            :verMail="true"
                            :verdireccion="false"
                            :verProfesion="false"
                            :verEstadoCivil="false"
                            :verNacionalidad="false"

                            :verSucursal="false"
                            :verUnidad="false"
                            :verSeccion="false"
                            :verCargo="true"
                            :verAfp="true"
                            :verPrevision="true"
                            :verMutual="false"
                            :verCaja="false"
                            />
                        </CCardBody>
                    </CCollapse>
                </CCard>
            </CCol>
            <CCol>
                <CCard accent-color="secondary" class="mt-1 mb-1">
                    <CCardHeader>
                        <div class="card-header-actions">
                            <CLink class="card-header-action btn-minimize float-right" @click="isCollapsedDocs = !isCollapsedDocs">
                                <CIcon :name="`cil-chevron-${isCollapsedDocs ? 'bottom' : 'top'}`"/>
                            </CLink>
                        </div>
                        <CRow><CCol><strong>Mis Últimos documentos</strong></CCol></CRow>
                    </CCardHeader>
                    <CCollapse :show="isCollapsedDocs" :duration="400">
                        <CCardBody>
                            <CCol sm="12" md="12" lg="12" v-bind:key="documento.mes" v-for="documento in documentos">
                                <CCard :class="documento.documentos.length>0?'tarjetaGestoDocumentosHay':'tarjetaGestoDocumentosNoHay'">
                                    <CCardHeader :style="documento.documentos.length>0?'':'background-color:#E57373'">
                                        <div class="card-header-actions">
                                            <CLink class="card-header-action btn-minimize float-right" @click="documento.isCollapsed = !documento.isCollapsed; (documento.isCollapsed && documento.docSeleccionado == null)?documento.docSeleccionado = documento.documentos[0]:0">
                                                <CIcon :name="`cil-chevron-${documento.isCollapsed ? 'top' : 'bottom'}`"/>
                                            </CLink>
                                            <router-link :to="'/remuneraciones/documentos_trabajador?tipoDoc=liquidaciones&docMes='+documento.numMes+'&periodo='+documento.anio+'&idEmpresa='+empresaSelected.idEmpresa">Ir a Liquidaciones</router-link>
                                            <CDropdown class="float-right" :caret="false">
                                                <template #toggler>
                                                    <CLink class="card-header-action btn-setting">
                                                        <CIcon :content="$options.freeSet.cilSettings" />
                                                    </CLink>
                                                </template>

                                                <div v-if="documento.documentos.length==0">
                                                    <CDropdownItem> 
                                                        <CIcon class="mr-2" :content="$options.freeSet.cilMeh" />
                                                        No hay documentos
                                                    </CDropdownItem>
                                                </div>
                                                <div v-else role="group" v-bind:key="doc.nombreDocumento" v-for="doc, index in documento.documentos">
                                                    
                                                    <CDropdownHeader>{{doc.nombreDocumento}}</CDropdownHeader>

                                                    <CDropdownItem @click="seleccionarDocumentoVer(documento, doc); documento.isCollapsed = true">
                                                        <CIcon :content="$options.freeSet.cilDescription" class="mr-2" /> Ver
                                                    </CDropdownItem>
                                                    
                                                    <CDropdownItem @click="descargarDocumento(doc)">
                                                        <CIcon :content="$options.freeSet.cilCloudDownload" class="mr-2" /> Descargar
                                                    </CDropdownItem>

                                                    <CDropdownDivider v-show="index+1 < documento.documentos.length" />
                                                </div>
                                            </CDropdown>
                                        </div>
                                        Mi última Liquidación ({{documento!=null?`${documento.mes} ${documento.anio}`:'Sin Documento'}}) <span v-show="documento.documentos.length==0">(Sin Documentos)</span>
                                    
                                    </CCardHeader>
                                    <CCollapse :show="documento.isCollapsed" :duration="400">
                                        <CCardBody>
                                            <div v-show="documento.documentos.length==0">  
                                                <CIcon class="mr-2" :content="$options.freeSet.cilMeh" /> No hay documentos
                                            </div>
                                            <div v-show="documento.documentos.length>1">
                                                <CDropdown
                                                    size="sm"
                                                    toggler-text="Seleccione Documento a ver"
                                                    color="dark"
                                                    class="m-0 mb-1 d-inline-block">
                                                    <CDropdownItem v-bind:key="doc.nombreDocumento" v-for="doc in documento.documentos" @click="seleccionarDocumentoVer(documento, doc)">{{doc.nombreDocumento}}</CDropdownItem>
                                                </CDropdown>
                                            </div>
                                            <iframe
                                                v-show="documento.documentos.length>0 && documento.docSeleccionado != null"
                                                :src="documento.docSeleccionado == null? '':documento.docSeleccionado.viewDocumento.replace('view', 'preview')"
                                                width="100%"
                                                height="400"
                                                allow="autoplay">
                                            </iframe>
                                        </CCardBody>
                                    </CCollapse>
                                </CCard>
                            </CCol>
                            <CCol sm="12" md="12" lg="12" v-bind:key="documento.fechaEmision" v-for="documento in documentosContratos">
                                                
                                <CCard :class="documento.documento != null ?'tarjetaGestoDocumentosHay':'tarjetaGestoDocumentosNoHay'">
                                    <CCardHeader>
                                        <div class="card-header-actions">
                                            <CLink class="card-header-action btn-minimize float-right" @click="documento.isCollapsed = !documento.isCollapsed; (documento.isCollapsed && documento.docSeleccionado == null)?documento.docSeleccionado = documento.documento:0">
                                                <CIcon :name="`cil-chevron-${documento.isCollapsed ? 'top' : 'bottom'}`"/>
                                            </CLink>
                                            <router-link :to="'/remuneraciones/documentos_trabajador?tipoDoc=contratos&idDoc='+documento.idContrato">Ir a Contratos</router-link>
                                            
                                            <CDropdown class="float-right" :caret="false">
                                                <template #toggler>
                                                    <CLink class="card-header-action btn-setting">
                                                        <CIcon :content="$options.freeSet.cilSettings" />
                                                    </CLink>
                                                </template>

                                                <div v-if="documento.documento == null">
                                                    <CDropdownItem> 
                                                        <CIcon class="mr-2" :content="$options.freeSet.cilMeh" />
                                                        No hay documentos
                                                    </CDropdownItem>
                                                </div>
                                                <div v-else role="group" >
                                                    
                                                    <CDropdownHeader>{{documento.documento.nombreDocumento}}</CDropdownHeader>

                                                    <CDropdownItem @click="seleccionarDocumentoVer(documento, documento.documento); documento.isCollapsed = true">
                                                        <CIcon :content="$options.freeSet.cilDescription" class="mr-2" /> Ver
                                                    </CDropdownItem>
                                                    
                                                    <CDropdownItem @click="descargarDocumento(documento.documento)">
                                                        <CIcon :content="$options.freeSet.cilCloudDownload" class="mr-2" /> Descargar
                                                    </CDropdownItem>

                                                </div>
                                            </CDropdown>
                                        </div>
                                        {{documento.tipo}} ({{formatFechaDocumentoNormalMesAnio(documento.fechaContrato)}}) <span v-show="documento.documento == null">(Sin Documentos)</span>
                                        
                                    </CCardHeader>
                                    <CCollapse :show="documento.isCollapsed" :duration="400">
                                        <CCardBody>
                                            <div v-show="documento.documento == null">  
                                                <CIcon class="mr-2" :content="$options.freeSet.cilMeh" /> No hay documentos
                                            </div>
                                                <iframe
                                                v-show="documento.documento != null && documento.docSeleccionado != null"
                                                :src="documento.docSeleccionado == null? '':documento.docSeleccionado.viewDocumento.replace('view', 'preview')"
                                                width="100%"
                                                height="400"
                                                allow="autoplay"
                                                ></iframe>
                                        </CCardBody>
                                    </CCollapse>
                                </CCard>
                            </CCol>
                            
                            <CargandoInfo :mostrarCarga="isLoadingLiquidaciones || isLoadingContratos" :mensajeInfo="'Obteniendo sus últimos documentos...'" />
                            

                            <!--<CJumbotron>
                            <h1 class="display-3">
                                <div class="c-avatar">
                                    <img
                                        style="height: 120px !important; width: 120px !important;"
                                        src="img/avatars/sin_avatar.png"
                                        class="c-avatar-img ml-5"
                                    />
                                </div>
                            </h1>
                            </CJumbotron>-->
                        </CCardBody>
                    </CCollapse>
                </CCard>
            </CCol>
        </CRow>
    </div>
    <!--<CContainer class="d-flex align-items-center" style="height: 75vh !important">
       
        <CRow class="w-100 justify-content-center">
            <CCol md="6">
                <div class="w-100">
                    <div class="clearfix">
                    <h1 class="float-left display-3 mr-4">
                        <CIcon
                        style="width: 3rem; height: 4rem; color: #2E2860;"
                        :content="$options.freeSet.cilMoodVeryGood"
                        />
                    </h1>
                    <h4 class="pt-3">Bienvenido {{ nombreUsuario }} a Gesto.</h4>
                    <p class="text-muted">  
                        Esperamos que tengas un buen día, recuerda cualquier problema con la plataforma no dudes en contactar a "soporte@vapp.cl".           
                    </p>
                    </div>
                </div>
            </CCol>
        </CRow>
    </CContainer>-->
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import cons from '@/const'
    import axios from "axios"
    import { freeSet } from "@coreui/icons";
    import Multiselect from 'vue-multiselect';
    import { format } from 'date-fns'
    import es from 'date-fns/locale/es'

    import InfoTrabajadorComponente from "@/views/trabajador/ComponenteInfoTrabajador.vue";
    
    import CargandoInfo from '@/views/utils/CargandoInfo'

    export default {
        name: "PrincipalTrabajador",
        freeSet,
        components: {
            Multiselect,
            InfoTrabajadorComponente,
            CargandoInfo
        },
        data() {
            return {
                urlBase: cons.port+"://" + cons.ipServer,
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,

                nombreUsuario: JSON.parse(this.$cookie.get("userLogginToken")).nombreUsuario,
                isCollapsedDocs: true,
                isCollapsedInfo: true,

                isLoadingEmpresas: false,
                mostrarEmpresas: false,
                empresasTrabajador: [],
                empresaSelected: {},

                
                isLoadingContratos: false,
                documentosContratos: [],

                isLoadingLiquidaciones: false,
                documentos: [],

                infoTrabajador: null,
                isLoadingInfo: false,
            };
        },
        beforeMount() {
            this.getEmpresasTrabajador();
        },
        methods: {
            getEmpresasTrabajador: function(){
                this.isLoadingEmpresas = true;
                var url = this.urlBase + "/get_empresas_trabajador"
                axios({
                    url: url,
                    method: 'POST',
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    },

                }).then((response) => {
                    
                    this.empresasTrabajador = response.data;
                    if(this.empresasTrabajador.length > 1){
                        this.mostrarEmpresas = true;
                    }
                    this.seleccionarEmpresa(this.empresasTrabajador[0])

                    this.isLoadingEmpresas = false;

                })
                .finally(() => {
                    this.isLoadingEmpresas = false
                });
            },
            seleccionarEmpresa: function(empresa){
                this.empresaSelected = empresa;
                this.obtenerLiquidacionesTrabajador()
                this.obtenerContratosTrabajador()
                this.obtenerInfo();
            },
            obtenerContratosTrabajador: function(){
                this.isLoadingContratos = true;
                var url = this.urlBase + "/get_documentos_trabajador"
                
                axios({
                    url: url,
                    method: 'POST',
                    "data": {
                        "info": {
                            "tipoBd": "REMU",
                            "tipoDoc": "contratos",
                            "idEmpresa": this.empresaSelected.idEmpresa,
                            "anio": null,
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    },

                }).then((response) => {
                    this.documentosContratos = response.data.documentos
                    this.isLoadingContratos = false;

                })
                .finally(() => {
                    this.isLoadingContratos = false
                });

            },

            obtenerLiquidacionesTrabajador: function(){
                this.isLoadingLiquidaciones = true;
                var url = this.urlBase + "/get_documentos_trabajador"
                
                axios({
                    url: url,
                    method: 'POST',
                    "data": {
                        "info": {
                            "tipoBd": "REMU",
                            "tipoDoc": "liquidaciones",
                            "idEmpresa": this.empresaSelected.idEmpresa,
                            "anio": null,
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    },

                }).then((response) => {
                    console.log("response.data", response.data)
                    this.documentos = response.data.documentos 
                    this.isLoadingLiquidaciones = false;

                })
                .finally(() => {
                    this.isLoadingLiquidaciones = false
                });


            },
            descargarDocumento: function(doc){
                const link = document.createElement('a')
                link.href = doc.urlDocumento
                link.download = doc.nombreDocumento
                link.click()
                URL.revokeObjectURL(link.href)
            },
            seleccionarDocumentoVer(documento, docSeleccionado){
                documento.docSeleccionado = docSeleccionado;
            },
            formatFechaDocumentoNormalMesAnio : function(fechaDoc){
                if (fechaDoc != null) {
                    var fecha = format(new Date(fechaDoc), cons.formatoDocumentosTrabajador, {locale: es})
                    return fecha[0].toUpperCase() + fecha.substring(1);
                } else {
                    return "-";
                }
            },


            obtenerInfo: function() {
                this.isLoadingInfo = true;
                var url = this.urlBase + "/get_info_trabajador"

                axios({
                    method: "POST",
                    url: url,
                    headers: {
                        Authorization: `${this.tokenLogin}`,
                    },
                    "data": {
                        "info": {
                            "tipoBd": "REMU",
                            "idEmpresa": this.empresaSelected.idEmpresa,
                        }
                    }
                    })
                    .then((result) => {
                        this.infoTrabajador = result.data.infoTrabajador;
                        this.isLoadingInfo = false;
                    },
                    (error) => {
                    
                        this.isLoadingInfo = false;
                    }
                    )
                    .finally(() => {
                        this.isLoadingInfo = false;
                    
                    });

            },

        }

    };
</script>

<style>


.tippy-tooltip.google-theme{
    background-color:#29235C;
    font-weight: normal;
}
.tippy-tooltip.google-theme .tippy-backdrop {
    
    background-color:#29235C;
}
.tippy-content{
    background-color: transparent;
}
</style>